import { BaseProgetto, Progetto } from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Spinner,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import {
  CheckboxField,
  DateField,
  InputAddSubtractOneChakraField,
  InputField,
  InputFileField,
  SelectChakraField,
  TextareaField,
  transformErrorForForm,
} from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useEffect, useMemo, useState } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import AutoCompleteField from '../../components/form/fields'

const initialValues: BaseProgetto = {
  codice: '',
  data_fine: '',
  data_inizio: '',
  titolo: '',
  controllo_budget: false,
  valuta_rendicontazione: undefined,
  valute_locali: [],
  allegato: null,
  allegato_2: null,
  allegato_3: null,
  allegato_4: null,
  allegato_5: null,
  area_intervento: null,
  codice_progetto_donatore: '',
  periodi_rendicontazione: 1,
  ente_principale: null,
  paesi_intervento: [],
  tipo_cambio: null,
  num_annualita: 1,
  settori_intervento: [],
  valuta_locale_principale: null,
  lingua_etichette: 'en',
  referente: null,
  note: '',
  progressivo_bozza: false,
}

interface ProgettoFormProps {
  progetto?: Progetto
  save: (progetto: Progetto) => Promise<void>
}

export default function ProgettoForm({ progetto, save }: ProgettoFormProps) {
  const { t } = useTranslation()
  const [showAllegati, setShowAllegati] = useState(0)

  const ProgettoSchema = Yup.object().shape({
    titolo: Yup.string().required().label(t('title')),
    codice: Yup.string().required().label(t('project_tdh')),
    data_inizio: Yup.string().required().label(t('from_date')),
    data_fine: Yup.string().required().label(t('to_date')),
    valuta_rendicontazione: Yup.number()
      .required()
      .label(t('reporting_currency')),
      valuta_locale_principale: Yup.number()
      .required()
      .label(t('main_local_currency')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: t('new_project'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${progetto?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, progetto])

  useEffect(() => {
    if (progetto) {
      setShowAllegati(progetto.numero_allegati)
    }
  }, [progetto])

  return (
    <Formik
      validationSchema={ProgettoSchema}
      onSubmit={(progetto, { setErrors, setSubmitting }) =>
        save({
          ...progetto,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(progetto ?? initialValues) as Progetto}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        values,
        handleReset,
        dirty,
      }) => (
        <form className="form-without-scrollbar" onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={progetto ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                     {isSubmitting && <Spinner size="sm" me={2} />}
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card overflow={'auto'} mt={2} className={'card-scroll-height'}>
              <CardBody>
                <Box
                  mb={2}
                  padding={2}
                  borderRadius={8}
                  background={'#F8F8F8'}
                  color={'darkblue'}
                  fontSize={16}
                  fontWeight={700}
                  width={'max-content'}
                >
                  {t('project_info')}
                </Box>
                <Grid minHeight="100%" templateColumns="repeat(4, 1fr)" gap={4}>
                  <GridItem colSpan={2} rowSpan={2}>
                    <TextareaField
                      name="titolo"
                      isRequired
                      rows={5}
                      label={t('title') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <InputField
                      name="codice"
                      isRequired
                      label={t('project_tdh') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <InputField
                      name="codice_progetto_donatore"
                      label={t('code_donator') ?? ''}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <AutoCompleteField
                      name="ente_principale"
                      label={t('main_entity') ?? ''}
                      url="/api/enti/options/"
                      keyQuery="enti-options"
                      value={values.ente_principale}
                    />
                  </GridItem>
                </Grid>
                <Box
                  mt={5}
                  mb={2}
                  padding={2}
                  borderRadius={8}
                  background={'#F8F8F8'}
                  color={'darkblue'}
                  fontSize={16}
                  fontWeight={700}
                  width={'max-content'}
                >
                  {t('period')}
                </Box>
                <Grid
                  mt={3}
                  minHeight="100%"
                  templateColumns="repeat(4, 1fr)"
                  gap={4}
                >
                  <GridItem>
                    <DateField
                      name="data_inizio"
                      isRequired
                      label={t('from_date') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <DateField
                      name="data_fine"
                      isRequired
                      label={t('to_date') ?? ''}
                    />
                  </GridItem>
                </Grid>
                <Box
                  mt={5}
                  mb={2}
                  padding={2}
                  borderRadius={8}
                  background={'#F8F8F8'}
                  color={'darkblue'}
                  fontSize={16}
                  fontWeight={700}
                  width={'max-content'}
                >
                  {t('categories')}
                </Box>
                <Grid
                  mt={3}
                  minHeight="100%"
                  templateColumns="repeat(4, 1fr)"
                  gap={4}
                >
                  <GridItem>
                    <AutoCompleteField
                      value={values.area_intervento}
                      url={'/api/aree-intervento/options/'}
                      keyQuery={'aree-intervento-options'}
                      name={'area_intervento'}
                      label={t('intervention_area') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <AutoCompleteField
                      value={values.paesi_intervento}
                      url={'/api/paesi-intervento/options/'}
                      keyQuery={'paesi-intervento-options'}
                      isMulti
                      name={'paesi_intervento'}
                      label={t('country_intervention') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <AutoCompleteField
                      value={values.settori_intervento}
                      url={'/api/settori-intervento/options/'}
                      keyQuery={'settori-intervento-options'}
                      name={'settori_intervento'}
                      isMulti
                      label={t('sectors_of_intervention') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <AutoCompleteField
                      value={values.referente}
                      url={
                        '/api/progetti/' + (progetto?.id ?? '') + '/options-referenti/'
                      }
                      keyQuery={'referenti-options'}
                      name={'referente'}
                      isDisabled={!progetto}
                      label={t('contact') ?? ''}
                    />
                  </GridItem>
                </Grid>
                <Box
                  mt={5}
                  mb={2}
                  padding={2}
                  borderRadius={8}
                  background={'#F8F8F8'}
                  color={'darkblue'}
                  fontSize={16}
                  fontWeight={700}
                  width={'max-content'}
                >
                  {t('accounting')}
                </Box>
                <Grid
                  mt={3}
                  minHeight="100%"
                  templateColumns="repeat(4, 1fr)"
                  gap={4}
                >
                  <GridItem>
                    <AutoCompleteField
                      name="valute_locali"
                      label={t('local_currencies') ?? ''}
                      url="/api/valute/options/"
                      keyQuery="valute-options"
                      value={values.valute_locali}
                      isMulti
                    />
                  </GridItem>
                  <GridItem>
                    <AutoCompleteField
                      name="valuta_rendicontazione"
                      label={t('reporting_currency') ?? ''}
                      url="/api/valute/options/"
                      keyQuery="valute-options"
                      value={values.valuta_rendicontazione}
                      isRequired
                    />
                  </GridItem>
                  <GridItem>
                    <AutoCompleteField
                      name="valuta_locale_principale"
                      label={t('main_local_currency') ?? ''}
                      url="/api/valute/options/"
                      keyQuery="valute-options"
                      value={values.valuta_locale_principale}
                      isRequired
                    />
                  </GridItem>
                </Grid>
                <Grid
                  mt={3}
                  minHeight="100%"
                  templateColumns="repeat(4, 1fr)"
                  gap={4}
                >
                  <GridItem>
                    <AutoCompleteField
                      name="tipo_cambio"
                      label={t('exchange_type') ?? ''}
                      url="/api/tipi-cambio/options/"
                      keyQuery="tipi-cambio-options"
                      value={values.tipo_cambio}
                    />
                  </GridItem>
                  <GridItem>
                    <AutoCompleteField
                      name="tipo_cambio_euro"
                      label={t('exchange_type_euro') ?? ''}
                      url="/api/tipi-cambio/options/"
                      keyQuery="tipi-cambio-options"
                      value={values.tipo_cambio_euro}
                    />
                  </GridItem>
                </Grid>
                <Box
                  mb={2}
                  mt={5}
                  padding={2}
                  borderRadius={8}
                  background={'#F8F8F8'}
                  color={'darkblue'}
                  fontSize={16}
                  fontWeight={700}
                  width={'max-content'}
                >
                  {t('options')}
                </Box>
                <Grid
                  mt={3}
                  minHeight="100%"
                  templateColumns="repeat(4, 1fr)"
                  gap={4}
                >
                  <GridItem>
                    <SelectChakraField
                      name="lingua_etichette"
                      label={t('labels_language') ?? ''}
                    >
                      <option value="it">{t('italian')}</option>
                      <option value="en">{t('english')}</option>
                      <option value="fr">{t('french')}</option>
                      <option value="es">{t('spanish')}</option>
                      <option value="pt">{t('portoguese')}</option>
                    </SelectChakraField>
                  </GridItem>
                  <GridItem>
                    <InputAddSubtractOneChakraField
                      name="periodi_rendicontazione"
                      label={t('number_of_reports') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <CheckboxField
                      name="controllo_budget"
                      label={t('enable_additional_controls') ?? ''}
                    />
                  </GridItem>
                  <GridItem>
                    <CheckboxField
                      name="progressivo_bozza"
                      label={t('progressive_draft') ?? ''}
                    />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <TextareaField name="note" label={t('notes') ?? ''} />
                  </GridItem>
                  <GridItem colSpan={4}>
                    <Button
                      onClick={() => {
                        setShowAllegati(showAllegati + 1)
                      }}
                      isDisabled={showAllegati === 5}
                    >
                      {t('add_file_attachment')}
                    </Button>
                  </GridItem>
                  {showAllegati > 0 &&
                    Array.from(Array(showAllegati)).map((item, index) => (
                      <GridItem colSpan={4} key={index}>
                        <InputFileField
                          name={
                            index === 0 ? 'allegato' : `allegato_${index + 1}`
                          }
                          label={t('attachment') + ' ' + (index + 1) ?? ''}
                        />
                      </GridItem>
                    ))}
                </Grid>
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
