import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Movimento } from '../types'

interface ModalLogsProps {
  title: string
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  logs: Movimento[]
}

export default function ModalLogsMovements({
  title,
  isOpen,
  onClose,
  logs,
}: ModalLogsProps) {
  const { t } = useTranslation()
  return (
    <Modal isCentered size={'4xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={-5} maxHeight={400} overflowY={'auto'} bg={'white'}>
          <Table variant={'pinc'}>
            <Thead position={'sticky'} top={0}>
              <Tr>
                <Th>
                  {t('progressive')}
                </Th>
                <Th>{t('email')}</Th>
                <Th>{t('date')}</Th>
                <Th>
                  {t('causale')}
                </Th>
                <Th>{t('description')}</Th>
                <Th>
                  {t('amount')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {logs.map((log) => (
                <Tr key={log.id}>
                  <Td>{log.progressivo}</Td>
                  <Td>{log?.utente_eliminazione}</Td>
                  <Td>{dayjs(log.data_eliminazione).format('DD/MM/YYYY')}</Td>
                  <Td>{log.causale}</Td>
                  <Td>{log.descrizione}</Td>
                  <Td>{log.importo}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter display={'flex'} justifyContent={'end'}>
          <Button
            size={'sm'}
            _hover={{
              bg: 'brand',
              color: 'white',
            }}
            onClick={onClose}
            bg={'brandSecondary'}
            color={'white'}
          >
            {t('close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
