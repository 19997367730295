import {
  Alert,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BsClockFill } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router'
import { useAuthUser } from 'use-eazy-auth'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import ModalDelete from '../../../components/ModalDelete'
import ModalLogs from '../../../components/ModalLogs'
import { CAUSALI_APPORTO_LOCO, OPTIONS_CAUSALI } from '../../../consts'
import {
  useCopiaMovimento,
  useCreateMovimento,
  useDeleteSoftMovimento,
  useMovimento,
} from '../../../hooks/movimenti'
import { useProgettoSimple } from '../../../hooks/progetti'
import {
  useCanEditMovimenti,
  useCanInsertMovimenti,
} from '../../../permissions'
import createStringLink, {
  getNameOfFileFromUrl,
  numberFormatter,
} from '../../../utils'
import ModalCopiaApportoLoco from './ModalCopiaApportoLoco'
import ModalCopiaMovimento from './ModalCopiaMovimento'
import LangLinkWithStyle from '../../../components/LangLinkWithStyle'

export default function Movimento() {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { id, movimento } = useParams()
  const { data: dataMovimento } = useMovimento(Number(movimento!))
  const { data: progetto } = useProgettoSimple(Number(id!))
  const deleteMovimento = useDeleteSoftMovimento()
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const {
    isOpen: isOpenCopia,
    onClose: onCloseCopia,
    onOpen: onOpenCopia,
    onToggle: onToggleCopia,
  } = useDisclosure()

  const {
    isOpen: isOpenLogs,
    onClose: onCloseLogs,
    onOpen: onOpenLogs,
    onToggle: onToggleLogs,
  } = useDisclosure()

  const {
    isOpen: isOpenCopiaApportoLoco,
    onClose: onCloseCopiaApportoLoco,
    onOpen: onOpenCopiaApportoLoco,
    onToggle: onToggleCopiaApportoLoco,
  } = useDisclosure()

  const userCanGestioneMovimenti = useCanEditMovimenti(Number(id!))
  const userCanInsertMovimenti = useCanInsertMovimenti(Number(id!))

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('movements'),
        link: `/projects/${id}/movements`,
      },
      {
        label:
          '(' +
          (dataMovimento?.progressivo ?? 'Draft') +
          ') - ' +
          t(
            OPTIONS_CAUSALI.find(
              (causale) => causale.value === dataMovimento?.causale
            )?.label ?? ''
          ) +
          ' - ' +
          numberFormatter.format(dataMovimento?.importo ?? 0) +
          ' ' +
          dataMovimento?.valuta_data?.codice,
      },
    ]
  }, [t, dataMovimento, progetto, id])

  const copiaMovimento = useCopiaMovimento()

  const createdAt = useMemo(() => {
    if (dataMovimento?.logs) {
      const date = dataMovimento?.logs.find(
        (log) => log.action === 'create'
      )?.timestamp
      return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : ''
    }
  }, [dataMovimento])

  const updatedAt = useMemo(() => {
    if (dataMovimento?.logs) {
      const date = dataMovimento?.logs[0]?.timestamp
      return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : ''
    }
  }, [dataMovimento])

  const updateBy = useMemo(() => {
    if (dataMovimento?.logs && dataMovimento.logs.length > 0) {
      if (
        dataMovimento?.logs[0]?.actor?.name &&
        dataMovimento?.logs[0]?.actor?.email
      ) {
        return (
          dataMovimento?.logs[0]?.actor?.name +
          ' ' +
          dataMovimento?.logs[0]?.actor?.email
        )
      } else {
        return ''
      }
    }
  }, [dataMovimento])

  const { user } = useAuthUser()

  const createMovimento = useCreateMovimento()

  const toast = useToast()

  const totalsMovimentiAnticipati = useMemo(() => {
    if (dataMovimento?.movimenti_anticipati_data) {
      return dataMovimento?.movimenti_anticipati_data?.reduce(
        (acc, movimento) => {
          acc.importo += Number(movimento.importo)
          acc.importo_iva += movimento.importo_iva
          return acc
        },
        {
          importo: 0,
          importo_iva: 0,
        }
      )
    } else {
      return {
        importo: 0,
        importo_iva: 0,
      }
    }
  }, [dataMovimento])

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          sticky
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanGestioneMovimenti && dataMovimento && (
                <>
                  <Button
                    size={'sm'}
                    _hover={{
                      bg: 'error',
                      color: 'white',
                      opacity: 0.8,
                    }}
                    onClick={onOpen}
                    bg={'error'}
                    color={'white'}
                  >
                    {t('delete')}
                  </Button>
                  <Button
                    size={'sm'}
                    ms={4}
                    isDisabled={
                      !dataMovimento?.editabile &&
                      !dataMovimento?.editable_by_user
                    }
                    color={'white'}
                    onClick={() => {
                      navigate(
                        createStringLink({
                          path: `/projects/${id}/movements/${dataMovimento?.id}/edit`,
                          lang: i18n.language,
                        })
                      )
                    }}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('edit')}
                  </Button>
                </>
              )}
            </Box>
          }
        />
        {dataMovimento?.bozza && (
          <Alert mt={2} mb={2} borderRadius={'md'}>
            <Text fontWeight={'500'} fontSize={14}>
              {t('this_movement_is_draft')}
            </Text>
          </Alert>
        )}
        {userCanInsertMovimenti && (
          <Card width={'100%'} bg={'card'} mb={2}>
            <CardBody py={2}>
              <Grid templateColumns="repeat(4, 1fr)" gap={2} width={'100%'}>
                <GridItem colSpan={1}>
                  <Button
                    size={'sm'}
                    border={'1px solid'}
                    borderColor={'brandBlue'}
                    color={'brandBlue'}
                    bg={'card'}
                    _hover={{
                      bg: 'brandBlue',
                      color: 'white',
                    }}
                    width={'100%'}
                    onClick={() => {
                      navigate(
                        createStringLink({
                          path: `/projects/${id}/movements/create?movimento=${dataMovimento?.id}`,
                          lang: i18n.language,
                        })
                      )
                    }}
                  >
                    {t('copy_movement')}
                  </Button>
                </GridItem>
                <GridItem colSpan={1}>
                  <Button
                    size={'sm'}
                    border={'1px solid'}
                    borderColor={'brandBlue'}
                    color={'brandBlue'}
                    bg={'card'}
                    isDisabled={
                      !dataMovimento?.editabile &&
                      !dataMovimento?.editable_by_user
                    }
                    _hover={{
                      bg: 'brandBlue',
                      color: 'white',
                    }}
                    width={'100%'}
                    onClick={() => {
                      onOpenCopia()
                    }}
                  >
                    {t('duplicate')}
                  </Button>
                </GridItem>
                {dataMovimento &&
                  CAUSALI_APPORTO_LOCO.includes(dataMovimento?.causale!) && (
                    <GridItem colSpan={1}>
                      <Button
                        size={'sm'}
                        isDisabled={!!dataMovimento.movimento_collegato}
                        border={'1px solid'}
                        borderColor={'brandBlue'}
                        color={'brandBlue'}
                        bg={'card'}
                        _hover={{
                          bg: 'brandBlue',
                          color: 'white',
                        }}
                        width={'100%'}
                        onClick={() => {
                          onOpenCopiaApportoLoco()
                        }}
                      >
                        {t('create_local_contribution')}
                      </Button>
                    </GridItem>
                  )}
              </Grid>
            </CardBody>
          </Card>
        )}
        <Card>
          <CardBody>
            <Grid templateColumns={'repeat(5, 1fr)'} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('progressive')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dataMovimento?.progressivo}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('causale')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {t(
                    OPTIONS_CAUSALI.find(
                      (causale) => causale.value === dataMovimento?.causale
                    )?.label ?? ''
                  )}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('document_number')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dataMovimento?.numero_documento}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('duty_station')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dataMovimento?.duty_station_data?.nome}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('amount')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {numberFormatter.format(dataMovimento?.importo ?? 0)}{' '}
                  {dataMovimento?.valuta_data?.codice}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('vat')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dataMovimento?.aliquota_iva} %
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('import_vat')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {numberFormatter.format(dataMovimento?.importo_iva ?? 0)}{' '}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('amount_rendicontation')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {numberFormatter.format(
                    dataMovimento?.importo_rendicontazione ?? 0
                  )}{' '}
                  {
                    dataMovimento?.progetto_data?.valuta_rendicontazione_data
                      ?.codice
                  }
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('amount_euro')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {numberFormatter.format(
                    dataMovimento?.importo_rendicontazione_euro ?? 0
                  )}{' '}
                  {'EUR'}
                </Text>
              </GridItem>
            </Grid>
            {dataMovimento?.causale === 'anticipo-cassa' &&
              !dataMovimento.anticipo_chiuso && (
                <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                  <GridItem>
                    <Text fontWeight={'500'} fontSize={14}>
                      {t('residual_cash_advance')}
                    </Text>
                  </GridItem>
                  <GridItem>
                    <Text color={'black'} fontWeight={700} fontSize={14}>
                      {numberFormatter.format(
                        dataMovimento?.residuo_anticipo ?? 0
                      )}{' '}
                      {dataMovimento?.valuta_data?.codice}
                    </Text>
                  </GridItem>
                </Grid>
              )}
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('payment_method')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dataMovimento?.modalita_pagamento_data?.descrizione}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('account')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dataMovimento?.conto_bancario && (
                    <LangLinkWithStyle
                      to={`/accounts/${dataMovimento?.conto_bancario}`}
                    >
                      {dataMovimento?.conto_bancario_data?.nome_banca} -{' '}
                      {dataMovimento?.conto_bancario_data?.numero_conto} -{' '}
                      {dataMovimento?.conto_bancario_data?.valuta_data?.codice}
                    </LangLinkWithStyle>
                  )}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('description')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dataMovimento?.descrizione}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('expense_type')}
                </Text>
              </GridItem>
              <GridItem>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dataMovimento?.tipo_spesa === 0
                    ? t('inelegible')
                    : t('eligible')}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('budget')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dataMovimento?.budget && (
                    <LangLinkWithStyle
                      to={`/projects/${progetto?.id}/budgets/${dataMovimento?.budget}`}
                    >
                      {dataMovimento?.budget_data?.codice} -{' '}
                      {dataMovimento?.budget_data?.descrizione}
                      {dataMovimento.budget_data?.codice}{' '}
                      {dataMovimento.budget_data?.codice_paese
                        ? `- ${dataMovimento.budget_data?.codice_paese}`
                        : ''}
                      {dataMovimento.budget_data?.codice_piano_conti
                        ? ` - ${dataMovimento.budget_data?.codice_piano_conti}`
                        : ''}
                    </LangLinkWithStyle>
                  )}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('date_registration')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dayjs(dataMovimento?.data_registrazione).format(
                    'DD/MM/YYYY'
                  )}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('date_competence')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dayjs(dataMovimento?.data_competenza).format('DD/MM/YYYY')}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('document_date')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dayjs(dataMovimento?.data_documento).format('DD/MM/YYYY')}
                </Text>
              </GridItem>
            </Grid>
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('entity')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dataMovimento?.ente && (
                    <LangLinkWithStyle to={`/entities/${dataMovimento?.ente}/`}>
                      {dataMovimento?.ente_data?.nome}
                    </LangLinkWithStyle>
                  )}
                </Text>
              </GridItem>
            </Grid>
            {dataMovimento?.fornitore && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('supplier')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {dataMovimento?.fornitore && (
                      <LangLinkWithStyle
                        to={`/suppliers/${dataMovimento?.fornitore}/`}
                      >
                        {dataMovimento?.fornitore_data?.ragione_sociale}
                      </LangLinkWithStyle>
                    )}
                  </Text>
                </GridItem>
              </Grid>
            )}
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('report')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dataMovimento?.periodo}
                </Text>
              </GridItem>
            </Grid>
            {dataMovimento?.bene && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('good')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {dataMovimento?.bene && (
                      <LangLinkWithStyle to={`/goods/${dataMovimento?.bene}/`}>
                        {dataMovimento?.bene_data?.descrizione}
                      </LangLinkWithStyle>
                    )}
                  </Text>
                </GridItem>
              </Grid>
            )}
            {dataMovimento?.collaborazione && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('collaboration')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {dataMovimento.collaborazione && (
                      <LangLinkWithStyle
                        to={`/projects/${dataMovimento.progetto}/collaborations/${dataMovimento.collaborazione}`}
                      >
                        {
                          dataMovimento?.collaborazione_data?.collaboratore_data
                            ?.nome
                        }
                      </LangLinkWithStyle>
                    )}
                  </Text>
                </GridItem>
              </Grid>
            )}
            {dataMovimento?.progetto_sorgente_data && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('source_project')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <LangLinkWithStyle
                      to={`/projects/${dataMovimento?.progetto_sorgente}/`}
                    >
                      {dataMovimento?.progetto_sorgente_data?.codice}
                    </LangLinkWithStyle>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {dataMovimento?.progetto_destinazione_data && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('destination_project')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <LangLinkWithStyle
                      to={`/projects/${dataMovimento?.progetto_destinazione}/`}
                    >
                      {dataMovimento?.progetto_destinazione_data?.codice}
                    </LangLinkWithStyle>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {dataMovimento?.modalita_pagamento_destinazione_data && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('payment_method_destination')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {
                      dataMovimento?.modalita_pagamento_destinazione_data
                        ?.descrizione
                    }
                  </Text>
                </GridItem>
              </Grid>
            )}
            {dataMovimento?.conto_destinazione_data && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('destination_account')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  {dataMovimento?.conto_destinazione_data && (
                    <Text color={'black'} fontWeight={700} fontSize={14}>
                      <LangLinkWithStyle
                        to={`/accounts/${dataMovimento?.conto_destinazione_data.id}`}
                      >
                        {dataMovimento?.conto_destinazione_data?.nome_banca} -{' '}
                        {dataMovimento?.conto_destinazione_data?.numero_conto} -{' '}
                        {
                          dataMovimento?.conto_destinazione_data?.valuta_data
                            ?.codice
                        }
                      </LangLinkWithStyle>
                    </Text>
                  )}
                </GridItem>
              </Grid>
            )}
            {dataMovimento?.importo_destinazione && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('amount_destination')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    {numberFormatter.format(
                      dataMovimento?.importo_destinazione ?? 0
                    )}{' '}
                    {dataMovimento?.valuta_destinazione_data?.codice}
                  </Text>
                </GridItem>
              </Grid>
            )}
            {dataMovimento?.related_record_trasferimento_data && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('movement_origin')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <LangLinkWithStyle
                      to={`/projects/${dataMovimento?.progetto}/movements/${dataMovimento?.related_record_trasferimento}`}
                    >
                      {dataMovimento?.related_record_trasferimento_data?.progressivo}{' '}
                      -{' '}
                      {dataMovimento.related_record_trasferimento_data.descrizione}
                    </LangLinkWithStyle>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {dataMovimento?.movimento_anticipo_data && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('advance_movement')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <LangLinkWithStyle
                      to={`/projects/${dataMovimento?.progetto}/movements/${dataMovimento?.movimento_anticipo}`}
                    >
                      {dataMovimento?.movimento_anticipo_data?.progressivo} -{' '}
                      {dataMovimento.movimento_anticipo_data.descrizione}
                    </LangLinkWithStyle>
                  </Text>
                </GridItem>
              </Grid>
            )}
            <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
              <GridItem>
                <Text fontWeight={'500'} fontSize={14}>
                  {t('notes')}
                </Text>
              </GridItem>
              <GridItem colSpan={4}>
                <Text color={'black'} fontWeight={700} fontSize={14}>
                  {dataMovimento?.note}
                </Text>
              </GridItem>
            </Grid>
            {dataMovimento?.allegato && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={dataMovimento?.allegato}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(dataMovimento?.allegato)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {dataMovimento?.allegato_2 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_2')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={dataMovimento?.allegato_2}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(dataMovimento?.allegato_2)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {dataMovimento?.allegato_3 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_3')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={dataMovimento?.allegato_3}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(dataMovimento?.allegato_3)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {dataMovimento?.allegato_4 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_4')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={dataMovimento?.allegato_4}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(dataMovimento?.allegato_4)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
            {dataMovimento?.allegato_5 && (
              <Grid templateColumns={'repeat(5, 1fr)'} mt={3} gap={6}>
                <GridItem>
                  <Text fontWeight={'500'} fontSize={14}>
                    {t('attachment_5')}
                  </Text>
                </GridItem>
                <GridItem colSpan={4}>
                  <Text color={'black'} fontWeight={700} fontSize={14}>
                    <Link
                      isExternal
                      color={'brand'}
                      href={dataMovimento?.allegato_5}
                      rel="noreferrer"
                    >
                      {getNameOfFileFromUrl(dataMovimento?.allegato_5)}
                    </Link>
                  </Text>
                </GridItem>
              </Grid>
            )}
          </CardBody>
          <CardFooter
            fontWeight={500}
            fontSize={12}
            minHeight={'56px'}
            boxShadow={'0 2px 10px rgba(0, 0, 0, 0.2)'}
            borderTop={'1px solid'}
            borderColor={'gray.200'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              {t('created_at')} {createdAt}, {t('last_update')} {updatedAt}{' '}
              {t('by')} {updateBy}
            </Box>
            {user.is_staff && (
              <Box
                display={'flex'}
                alignItems={'center'}
                _hover={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => onOpenLogs()}
              >
                <BsClockFill color="orange" />{' '}
                <Text ms={2}>{t('view_the_movement_story')}</Text>
              </Box>
            )}
          </CardFooter>
        </Card>
        {dataMovimento &&
          dataMovimento.movimenti_anticipati_data &&
          dataMovimento.movimenti_anticipati_data?.length > 0 && (
            <Card mt={6}>
              <CardHeader
                color={''}
                fontWeight={600}
                bg={'card'}
                py={2}
                borderBottom={1}
                width={'100%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                borderBottomColor={'brand'}
              >
                {t('advance_movements')}
              </CardHeader>
              <CardBody>
                <Table variant={'pinc'}>
                  <Thead position={'sticky'} top={0}>
                    <Tr>
                      <Th>{t('progressive')}</Th>
                      <Th>{t('date_competence')}</Th>
                      <Th>{t('date_registration')}</Th>
                      <Th>{t('causale')}</Th>
                      <Th>{t('description')}</Th>
                      <Th>{t('amount')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dataMovimento?.movimenti_anticipati_data?.map(
                      (movimento) => (
                        <Tr>
                          <Td>
                            <LangLinkWithStyle
                              to={`/projects/${movimento.progetto}/movements/${movimento.id}`}
                            >
                              {movimento.progressivo}
                            </LangLinkWithStyle>
                          </Td>
                          <Td>
                            {dayjs(movimento.data_competenza).format(
                              'DD/MM/YYYY'
                            )}
                          </Td>
                          <Td>
                            {dayjs(movimento.data_registrazione).format(
                              'DD/MM/YYYY'
                            )}
                          </Td>
                          <Td>
                            {t(
                              OPTIONS_CAUSALI.find(
                                (causale) => causale.value === movimento.causale
                              )?.label ?? ''
                            )}
                          </Td>
                          <Td>{movimento.descrizione}</Td>
                          <Td>
                            {numberFormatter.format(movimento.importo ?? 0)}{' '}
                            {movimento.valuta_data?.codice}
                          </Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          )}
        {dataMovimento &&
          dataMovimento.movimenti_anticipati_data &&
          dataMovimento.movimenti_anticipati_data?.length > 0 && (
            <Card mt={6}>
              <CardHeader
                color={''}
                fontWeight={600}
                bg={'card'}
                py={2}
                borderBottom={1}
                width={'100%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                borderBottomColor={'brand'}
              >
                {t('totals_advance_movements')}
              </CardHeader>
              <CardBody>
                <Table variant={'pinc'}>
                  <Thead position={'sticky'} top={0}>
                    <Tr>
                      <Th>{t('totals_advance_movements')}</Th>
                      <Th>{t('total_residuo_anticipo')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        {numberFormatter.format(
                          totalsMovimentiAnticipati.importo
                        )}{' '}
                        {dataMovimento?.valuta_data?.codice}
                      </Td>
                      <Td>
                        {numberFormatter.format(
                          dataMovimento?.residuo_anticipo ?? 0
                        )}{' '}
                        {dataMovimento?.valuta_data?.codice}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          )}
        {dataMovimento &&
          dataMovimento.movimenti_copia_data &&
          dataMovimento.movimenti_copia_data?.length > 0 && (
            <Card mt={6}>
              <CardHeader
                color={''}
                fontWeight={600}
                bg={'card'}
                py={2}
                borderBottom={1}
                width={'100%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                borderBottomColor={'brand'}
              >
                {t('multiple_insert')}
              </CardHeader>
              <CardBody>
                <Table variant={'pinc'}>
                  <Thead position={'sticky'} top={0}>
                    <Tr>
                      <Th>{t('project')}</Th>
                      <Th>{t('progressive')}</Th>
                      <Th>{t('budget')}</Th>
                      <Th>{t('amount')}</Th>
                      <Th>{t('vat')}</Th>
                      <Th>{t('import_vat')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dataMovimento?.movimenti_copia_data?.map((movimento) => (
                      <Tr>
                        <Td>
                          <LangLinkWithStyle
                            to={`/projects/${movimento.progetto}/`}
                          >
                            {movimento.progetto_data?.codice}
                          </LangLinkWithStyle>
                        </Td>
                        <Td>
                          <LangLinkWithStyle
                            to={`/projects/${movimento.progetto}/movements/${movimento.id}`}
                          >
                            {movimento.progressivo}
                          </LangLinkWithStyle>
                        </Td>
                        <Td>
                          {movimento.budget_data?.codice} -{' '}
                          {movimento.budget_data?.descrizione}
                        </Td>
                        <Td>
                          {numberFormatter.format(movimento.importo ?? 0)}{' '}
                          {movimento.valuta_data?.codice}
                        </Td>
                        <Td>{movimento.aliquota_iva} %</Td>
                        <Td>
                          {numberFormatter.format(movimento.importo_iva ?? 0)}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          )}
      </Flex>
      <ModalDelete
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={async () => {
          await deleteMovimento.mutateAsync(+movimento!).then(() => {
            navigate(
              createStringLink({
                path: `/projects/${id}/movements`,
                lang: i18n.language,
              })
            )
          })
        }}
        onOpen={onOpen}
        onToggle={onToggle}
        nameItem={
          '(' +
          (dataMovimento?.progressivo ?? 'Draft') +
          ') ' +
          dataMovimento?.causale
        }
        title={t('delete') + ' ' + t('movement')}
      />
      <ModalCopiaMovimento
        movimento={dataMovimento}
        isOpen={isOpenCopia}
        onClose={onCloseCopia}
        onConfirm={async (values) => {
          await copiaMovimento
            .mutateAsync({
              id: +movimento!,
              ...values,
            })
            .then((res) => {
              const numeroCopie = res
              onCloseCopia()
              toast({
                title: t('copy_movement'),
                description:
                  t('you_have_created') + ' ' + numeroCopie + ' ' + t('copies'),
                status: 'success',
                duration: 10000,
                isClosable: true,
              })
              navigate(
                createStringLink({
                  path: `/projects/${id}/movements`,
                  lang: i18n.language,
                })
              )
            })
        }}
        onOpen={onOpenCopia}
        onToggle={onToggleCopia}
      />
      <ModalLogs
        isOpen={isOpenLogs}
        onClose={onCloseLogs}
        onOpen={onOpenLogs}
        onToggle={onToggleLogs}
        title={
          t('history_of_movement') +
          ' - ' +
          (dataMovimento?.progressivo ?? 'Draft') +
          ' | ' +
          OPTIONS_CAUSALI.find(
            (causale) => causale.value === dataMovimento?.causale
          )?.label
        }
        logs={dataMovimento?.logs || []}
      />
      {isOpenCopiaApportoLoco && (
        <ModalCopiaApportoLoco
          isOpen={isOpenCopiaApportoLoco}
          movimento={dataMovimento}
          onClose={onCloseCopiaApportoLoco}
          onOpen={onOpenCopiaApportoLoco}
          onToggle={onToggleCopiaApportoLoco}
          progetto={progetto}
          onConfirm={async (movimento) => {
            await createMovimento
              .mutateAsync({
                movimento_collegato: +movimento!,
                ...dataMovimento,
                ...movimento,
                causale: 'apporto-loco',
              })
              .then((res) => {
                onCloseCopiaApportoLoco()
                navigate(
                  createStringLink({
                    path: `/projects/${res.progetto}/movements/${res.id}`,
                    lang: i18n.language,
                  })
                )
              })
          }}
        />
      )}
    </Box>
  )
}
