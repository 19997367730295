import { BaseSaldoCertificato, SaldoCertificato } from '../../types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { InputField, transformErrorForForm } from '../../components/form'
import HeaderActionsPage from '../../components/HeaderActionsPage'
import { useMemo } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import AutoCompleteField, { DateField } from '../../components/form/fields'
import { useQsFilters } from '../../hooks/filters'
import fi from 'date-fns/esm/locale/fi/index'

interface SaldoCertificatoFormProps {
  saldo?: SaldoCertificato
  save: (saldo: SaldoCertificato) => Promise<void>
}

const initFilters = (params: URLSearchParams) => ({
  conto_bancario: params.get('conto_bancario') ?? '',
  progetto: params.get('progetto') ?? '',
})

export default function SaldoCertficatoForm({
  saldo,
  save,
}: SaldoCertificatoFormProps) {
  const { t } = useTranslation()

  const { filters } = useQsFilters(initFilters)

  const SaldoCertficatoSchema = Yup.object().shape({
    conto: Yup.number().required().label(t('account')),
    progetto: Yup.number().required().label(t('project')),
    data_saldo: Yup.string().required().label(t('date_balance')),
    saldo: Yup.number().required().label(t('balance')),
  })

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('certified_balances'),
        link: '/certified-balances',
      },
      {
        label: t('new_certified_balance'),
      },
    ]
  }, [t])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('certified_balances'),
        link: '/certified-balances',
      },
      {
        label:
          saldo?.conto_bancario_data?.nome_banca +
          ' - ' +
          saldo?.progetto_data?.codice,
        link: `/certified-balances/${saldo?.id}`,
      },
      {
        label: t('edit'),
      },
    ]
  }, [t, saldo])

  const initialValues: BaseSaldoCertificato = {
    progetto: filters.progetto ? Number(filters.progetto) : undefined,
    conto: filters.conto_bancario ? Number(filters.conto_bancario) : undefined,
    data_saldo: '',
    saldo: undefined,
  }

  return (
    <Formik
      validationSchema={SaldoCertficatoSchema}
      onSubmit={(saldo, { setErrors, setSubmitting }) =>
        save({
          ...saldo,
        }).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }
      validateOnMount
      initialValues={(saldo ?? initialValues) as SaldoCertificato}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        values,
        handleReset,
        dirty,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={saldo ? itemsBreadCrumbEdit : itemsBreadCrumbCreate}
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <Card mt={2}>
              <CardBody>
                <Grid minHeight="100%" templateColumns="repeat(2, 1fr)" gap={4}>
                  <GridItem>
                    <AutoCompleteField
                      isRequired
                      label={t('project') ?? ''}
                      name={'progetto'}
                      value={values.progetto}
                      onChange={(value) => {
                        setFieldValue('progetto', value)
                        setFieldValue('conto', undefined)
                        setFieldValue('data_saldo', value.data_blocco_compentenza)
                      }}
                      params={{
                        data_blocco_compentenza__isnull: false,
                      }}
                      url={'/api/progetti/options/'}
                      keyQuery={'progetti-options'}
                    />
                  </GridItem>
                  <GridItem>
                    <AutoCompleteField
                      isRequired
                      label={t('account') ?? ''}
                      name={'conto'}
                      params={{ progetto: values.progetto }}
                      isDisabled={!values.progetto}
                      value={values.conto}
                      url={values.progetto ? `/api/progetti/${values.progetto}/options-conti-bancari/` : '/api/conti-bancari/options/'}
                      keyQuery="conti-bancari-options"
                    />
                  </GridItem>
                  <GridItem>
                    <DateField
                      isRequired
                      label={t('date_balance') ?? ''}
                      name={'data_saldo'}
                    />
                  </GridItem>
                  <GridItem>
                    <InputField
                      isRequired
                      label={t('balance') ?? ''}
                      name={'saldo'}
                      type={'number'}
                    />
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </Flex>
        </form>
      )}
    </Formik>
  )
}
