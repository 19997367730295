import * as Yup from 'yup'
import { FieldArray, Formik, useFormikContext } from 'formik'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  useDisclosure,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useEffect, useMemo, useState } from 'react'
import { AccountUser, Movimento, Progetto } from '../../../types'
import { useLocation, useParams } from 'react-router'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import {
  DateField,
  InputField,
  InputFileField,
  ReactSelectField,
  SelectChakraField,
  TextareaField,
  transformErrorForForm,
} from '../../../components/form'
import BreadCrumb from '../../../components/BreadCrumb'
import { useProgettoSimple } from '../../../hooks/progetti'
import {
  OPTIONS_CAUSALI,
  TIPO_PROCEDURA_ACQUISTO_OPTIONS,
} from '../../../consts'
import { orderBy } from 'lodash'
import dayjs from 'dayjs'
import AutoCompleteField from '../../../components/form/fields'
import { useModalitaPagamento } from '../../../hooks/modalita-pagamento'
import { useCausaleContextState } from '../../../context/CausaleContext'
import { BiDuplicate, BiPlus, BiTrash } from 'react-icons/bi'
import { useAuthUser } from 'use-eazy-auth'
import ReactRouterPrompt from 'react-router-prompt'

const AmountField = ({ index }: { index: number }) => {
  const { t } = useTranslation()

  useEffect(() => {
    // avoid up and down on importo field

    document
      .getElementById('movimenti.' + index + '.importo')
      ?.addEventListener('keydown', (e) => {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault()
        }
      })

    return () => {
      document
        .getElementById('movimenti.' + index + '.importo')
        ?.removeEventListener('keydown', (e) => {
          if (e.which === 38 || e.which === 40) {
            e.preventDefault()
          }
        })
    }
  }, [])

  return (
    <InputField
      name={'movimenti.' + index + '.importo'}
      id={'movimenti.' + index + '.importo'}
      label={t('amount') ?? ''}
      isRequired
      type="number"
      step="0"
    />
  )
}

export function fromNullToUndefineFromObjectWithTypes(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      obj[key] = undefined
    }
  })
  return obj
}

interface MovimentoFormProps {
  movimenti?: Movimento[]
  movimentoFromCopia?: number
  save: (values: any) => Promise<void>
}

interface MovimentiMultilple {
  movimenti: Movimento[]
}

export function MovimentoGridForm({
  progetto,
  movimenti,
  modeForm = 'horizontal',
}: {
  progetto: Progetto | undefined
  movimenti: Movimento[] | undefined
  modeForm: 'horizontal' | 'vertical'
}) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const {
    setFieldValue,
    values,
    handleSubmit,
    setErrors,
    errors,
    isSubmitting,
    isValid,
    dirty: isDirty,
    validateForm,
  } = useFormikContext<MovimentiMultilple>()
  const { causali } = useCausaleContextState()

  const { user } = useAuthUser<AccountUser>()

  const { data: modalitaPagamento } = useModalitaPagamento()

  const optionsModalitaPagamento = useMemo(() => {
    const modalitaPagamentoData = modalitaPagamento?.results || []
    const modalitaPagamentoOptions = modalitaPagamentoData.map((v) => ({
      value: v.id,
      label: v.descrizione,
    }))
    return modalitaPagamentoOptions
  }, [modalitaPagamento])

  const optionsCausali = useMemo(() => {
    const options = OPTIONS_CAUSALI.map((v) => ({
      value: v.value,
      label: t(v.label),
    }))
    return orderBy(options, ['label'], ['asc'])
  }, [t])

  const optionsValute = useMemo(() => {
    const valuteLocali = progetto?.valute_locali_data || []
    const valute = valuteLocali.map((v) => ({
      value: v.id,
      label: v.codice,
    }))
    return valute
  }, [progetto])

  const numeroAnnualita = useMemo(() => {
    if (!progetto) return 1
    return progetto.periodi_rendicontazione ?? 1
  }, [progetto])

  useEffect(() => {
    if (movimenti && movimenti.length > 0) {
      movimenti.forEach((movimento, index) => {
        setFieldValue(
          'movimenti.' + index + '.causale_oggetto',
          causali[movimento.causale as keyof typeof causali]
        )
      })
    }
  }, [movimenti, causali, setFieldValue])

  return (
    <FieldArray
      name="movimenti"
      render={(arrayHelpers) => (
        <Box
          maxHeight={'calc(100vh - 150px)'}
          className={'card-scroll-height'}
          overflow={'auto'}
        >
          <Button
            position={'fixed'}
            right={10}
            bottom={10}
            zIndex={100}
            onClick={() => {
              // validate form before adding new movimento
              validateForm()

              arrayHelpers.push({
                causale: undefined,
                descrizione: '',
                data_documento: dayjs().format('YYYY-MM-DD'),
                data_competenza:
                  dayjs(progetto?.data_blocco_compentenza)
                    .add(1, 'day')
                    .format('YYYY-MM-DD') ?? dayjs().format('YYYY-MM-DD'),
                ente: undefined,
                beneficiario: '',
                fornitore: undefined,
                donatore: undefined,
                bene: undefined,
                collaborazione: undefined,
                tipo_spesa: 0,
                budget: undefined,
                periodo: undefined,
                tipo_procedura_acquisto: undefined,
                modalita_pagamento: undefined,
                conto_bancario: undefined,
                valuta: undefined,
                importo: 0,
                importo_iva: 0,
                aliquota_iva: 0,
                importo_rendicontazione: 0,
                movimento_anticipo: undefined,
                allegato: undefined,
                allegato_2: undefined,
                allegato_3: undefined,
                allegato_4: undefined,
                allegato_5: undefined,
                numero_documento: '',
                duty_station: user?.permessi?.duty_station || undefined,
              })
            }}
            size={'lg'}
            color={'white'}
            bg={'brand'}
            borderRadius={'50%'}
            padding={0}
            boxShadow={'0 0 10px rgba(0,0,0,0.3)'}
            _hover={{
              bg: 'brandSecondary',
              color: 'white',
            }}
          >
            <BiPlus size={30} />
          </Button>
          <Grid
            width={
              modeForm === 'horizontal'
                ? 'max-content'
                : `calc(350px * ${values.movimenti.length ?? 0})`
            }
            templateColumns={
              modeForm === 'horizontal'
                ? 'unset'
                : 'repeat(auto-fit, minmax(250px, 1fr))'
            }
          >
            {values.movimenti.map((mov, index) => (
              <GridItem
                key={index}
                width={modeForm === 'horizontal' ? 'max-content' : '97%'}
              >
                <Card mb={4}>
                  <CardHeader
                    py={2}
                    bg={'brandLight'}
                    fontSize={14}
                    fontWeight={500}
                  >
                    <Flex alignItems={'center'}>
                      <Box>
                        {t('movement')}{' '}
                        {movimenti
                          ? values.movimenti[index].progressivo
                          : index + 1}
                      </Box>
                      {!values.movimenti[index].id && (
                        <Button
                          ms={4}
                          onClick={() => arrayHelpers.remove(index)}
                          outlineColor={'red.400'}
                          bg={'white'}
                          color={'red.400'}
                          _hover={{ bg: 'red.400', color: 'white' }}
                          size={modeForm === 'horizontal' ? 'sm' : 'xs'}
                          outline={'1px solid'}
                          leftIcon={<BiTrash />}
                        >
                          {t('delete')}
                        </Button>
                      )}
                      {!values.movimenti[index].id && (
                        <Button
                          ms={4}
                          onClick={() => {
                            validateForm()
                            const movimento = values.movimenti[index]
                            const newMovimento = {
                              ...movimento,
                              id: undefined,
                              progressivo: undefined,
                            }
                            arrayHelpers.insert(index + 1, newMovimento)
                          }}
                          outlineColor={'brandSecondary'}
                          color={'brandSecondary'}
                          bg={'white'}
                          _hover={{ bg: 'brandSecondary', color: 'white' }}
                          outline={'1px solid'}
                          size={modeForm === 'horizontal' ? 'sm' : 'xs'}
                          leftIcon={<BiDuplicate />}
                        >
                          {t('duplicate')}
                        </Button>
                      )}
                      {modeForm === 'horizontal' && (
                        <Box ms={5} display={'flex'} alignItems={'center'}>
                          <Box fontSize={'sm'} me={2}>
                            {t('draft')}
                          </Box>
                          <Switch
                            isChecked={values.movimenti[index].bozza}
                            onChange={(e) => {
                              setFieldValue(
                                'movimenti.' + index + '.bozza',
                                e.target.checked
                              )
                            }}
                            colorScheme="orange"
                            size="lg"
                            mr={2}
                          />
                        </Box>
                      )}
                    </Flex>
                  </CardHeader>
                  <CardBody>
                    <Grid
                      width={modeForm === 'horizontal' ? '300vw' : '300px'}
                      overflowX={'scroll'}
                      gridTemplateColumns={
                        'repeat(auto-fit, minmax(150px, 1fr))'
                      }
                      gap={4}
                    >
                      {modeForm === 'vertical' && (
                        <GridItem>
                          <Box
                            width={'100%'}
                            display={'flex'}
                            alignItems={'center'}
                          >
                            <Box fontSize={'sm'} me={2}>
                              {t('draft')}
                            </Box>
                            <Switch
                              isChecked={values.movimenti[index].bozza}
                              onChange={(e) => {
                                setFieldValue(
                                  'movimenti.' + index + '.bozza',
                                  e.target.checked
                                )
                              }}
                              colorScheme="orange"
                              size="lg"
                              mr={2}
                            />
                          </Box>
                        </GridItem>
                      )}
                      <GridItem>
                        <ReactSelectField
                          name={'movimenti.' + index + '.causale'}
                          label={t('causale') ?? ''}
                          isRequired={true}
                          value={
                            optionsCausali.find(
                              (option) =>
                                option.value === values.movimenti[index].causale
                            ) ?? ''
                          }
                          onChange={(option) => {
                            if (!option) {
                              setFieldValue(
                                'movimenti.' + index + '.causale',
                                undefined
                              )
                              setFieldValue(
                                'movimenti.' + index + '.causale_oggetto',
                                undefined
                              )
                            } else {
                              setFieldValue(
                                'movimenti.' + index + '.causale',
                                option.value
                              )
                              setFieldValue(
                                'movimenti.' + index + '.causale_oggetto',
                                causali[option.value as keyof typeof causali]
                              )
                              setFieldValue(
                                'movimenti.' + index + '.donatore',
                                ''
                              )
                              setFieldValue(
                                'movimenti.' + index + '.fornitore',
                                ''
                              )
                              setFieldValue('movimenti.' + index + '.bene', '')
                              setFieldValue(
                                'movimenti.' + index + '.budget',
                                ''
                              )
                              setFieldValue(
                                'movimenti.' + index + '.collaborazione',
                                ''
                              )
                              setFieldValue(
                                'movimenti.' + index + '.progetto_destinazione',
                                ''
                              )

                              setFieldValue(
                                'movimenti.' + index + '.conto_destinazione',
                                ''
                              )
                              setFieldValue(
                                'movimenti.' +
                                  index +
                                  '.modalita_pagamento_destinazione',
                                ''
                              )
                              setFieldValue(
                                'movimenti.' + index + '.valuta_destinazione',
                                ''
                              )

                              setFieldValue(
                                'movimenti.' + index + '.importo_destinazione',
                                ''
                              )

                              const newCausale =
                                causali[option.value as keyof typeof causali]
                              const tipoSpesaDefault = newCausale.defaults
                              if (tipoSpesaDefault.length > 0) {
                                setFieldValue(
                                  'movimenti.' + index + '.tipo_spesa',
                                  Number(tipoSpesaDefault[0][1])
                                )
                              } else {
                                setFieldValue(
                                  'movimenti.' + index + '.tipo_spesa',
                                  undefined
                                )
                              }
                              if (option.value === 'spesa-italia') {
                                const modalitaPagamentoBancaItalia =
                                  optionsModalitaPagamento.find(
                                    (option) =>
                                      option.label.indexOf('BANCA ITALIA') > -1
                                  )
                                setFieldValue(
                                  'movimenti.' + index + '.modalita_pagamento',
                                  modalitaPagamentoBancaItalia?.value
                                )
                              }
                            }
                          }}
                          options={optionsCausali}
                        />
                      </GridItem>
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes(
                          'numero_documento'
                        ) &&
                        !values.movimenti[index].causale_oggetto.hide.includes(
                          'numero_documento'
                        ) && (
                          <GridItem>
                            <InputField
                              name={'movimenti.' + index + '.numero_documento'}
                              label={t('document_number') ?? ''}
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes(
                                  'numero_documento'
                                )
                              }
                            />
                          </GridItem>
                        )}
                      <GridItem>
                        <AutoCompleteField
                          name={'movimenti.' + index + '.duty_station'}
                          value={values.movimenti[index].duty_station}
                          url="/api/duty-stations/options/"
                          keyQuery="duty-stations-options"
                          label={t('duty_station') ?? ''}
                          isReadOnly
                        />
                      </GridItem>
                      <GridItem>
                        <TextareaField
                          name={'movimenti.' + index + '.descrizione'}
                          rows={2}
                          label={t('description') ?? ''}
                          isRequired={true}
                        />
                      </GridItem>
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes(
                          'movimento_anticipo'
                        ) && (
                          <GridItem colSpan={1} rowSpan={1}>
                            <AutoCompleteField
                              name={
                                'movimenti.' + index + '.movimento_anticipo'
                              }
                              value={values.movimenti[index].movimento_anticipo}
                              onChange={(value: any) => {
                                if (value?.value) {
                                  setFieldValue(
                                    'movimenti.' +
                                      index +
                                      '.movimento_anticipo',
                                    value?.value
                                  )
                                  setFieldValue(
                                    'movimenti.' + index + '.valuta',
                                    value?.valuta
                                  )
                                  setFieldValue(
                                    'movimenti.' + index + '.importo',
                                    value?.importo
                                  )
                                } else {
                                  setFieldValue(
                                    'movimenti.' +
                                      index +
                                      '.movimento_anticipo',
                                    undefined
                                  )
                                }
                              }}
                              url="/api/movimenti/options/"
                              keyQuery="movimenti-options"
                              params={{
                                progetto: id,
                                causale: 'anticipo-cassa',
                              }}
                              label={t('advance_movement') ?? ''}
                              isRequired={false}
                            />
                          </GridItem>
                        )}
                      <GridItem>
                        <DateField
                          name={'movimenti.' + index + '.data_documento'}
                          label={t('document_date') ?? ''}
                          isRequired
                          portalId="root-portal"
                        />
                      </GridItem>
                      <GridItem>
                        <DateField
                          name={'movimenti.' + index + '.data_competenza'}
                          label={t('competence_date') ?? ''}
                          isRequired
                          portalId="root-portal"
                          min={
                            progetto?.data_blocco_compentenza
                              ? dayjs(progetto?.data_blocco_compentenza)
                                  .add(1, 'day')
                                  .format('YYYY-MM-DD')
                              : undefined
                          }
                        />
                      </GridItem>
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes('ente') && (
                          <GridItem>
                            <AutoCompleteField
                              name={'movimenti.' + index + '.ente'}
                              value={values.movimenti[index].ente}
                              url="/api/enti/options/"
                              keyQuery="enti-options"
                              label={t('entity') ?? ''}
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes('ente')
                              }
                            />
                          </GridItem>
                        )}
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes('beneficiario') && (
                          <GridItem>
                            <InputField
                              name={'movimenti.' + index + '.beneficiario'}
                              label={t('beneficiary') ?? ''}
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes(
                                  'beneficiario'
                                )
                              }
                            />
                          </GridItem>
                        )}
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes('fornitore') &&
                        !values.movimenti[index].causale_oggetto.hide.includes(
                          'fornitore'
                        ) && (
                          <GridItem>
                            <AutoCompleteField
                              name={'movimenti.' + index + '.fornitore'}
                              value={values.movimenti[index].fornitore}
                              url={`/api/progetti/${id}/options_fornitori/`}
                              keyQuery="fornitori-options"
                              label={t('supplier') ?? ''}
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes(
                                  'fornitore'
                                ) &&
                                !values.movimenti[index].bozza
                              }
                            />
                          </GridItem>
                        )}
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes('donatore') &&
                        !values.movimenti[index].causale_oggetto.hide.includes(
                          'donatore'
                        ) && (
                          <GridItem>
                            <AutoCompleteField
                              name={'movimenti.' + index + '.donatore'}
                              value={values.movimenti[index].donatore}
                              url={`/api/progetti/${id}/options_donatori/`}
                              keyQuery="donatori-options"
                              label={t('donor') ?? ''}
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes(
                                  'donatore'
                                ) &&
                                !values.movimenti[index].bozza
                              }
                            />
                          </GridItem>
                        )}
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes('bene') &&
                        !values.movimenti[index].causale_oggetto.hide.includes(
                          'bene'
                        ) && (
                          <GridItem>
                            <AutoCompleteField
                              name={'movimenti.' + index + '.bene'}
                              value={values.movimenti[index].bene}
                              url="/api/beni/options/"
                              keyQuery="beni-options"
                              label={t('good') ?? ''}
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes('bene') &&
                                !values.movimenti[index].bozza
                              }
                            />
                          </GridItem>
                        )}
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes('collaborazione') &&
                        !values.movimenti[index].causale_oggetto.hide.includes(
                          'collaborazione'
                        ) && (
                          <GridItem>
                            <AutoCompleteField
                              name={'movimenti.' + index + '.collaborazione'}
                              value={values.movimenti[index].collaborazione}
                              url={`/api/progetti/${progetto?.id}/options-collaborazioni/`}
                              keyQuery="collaborazioni-options"
                              label={t('collaboration') ?? ''}
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes(
                                  'collaborazione'
                                ) &&
                                !values.movimenti[index].bozza
                              }
                            />
                          </GridItem>
                        )}
                      <GridItem colSpan={1}>
                        <SelectChakraField
                          name={'movimenti.' + index + '.tipo_spesa'}
                          isDisabled={
                            values.movimenti[index].causale_oggetto &&
                            values.movimenti[
                              index
                            ].causale_oggetto.disabled.includes('tipo_spesa')
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'movimenti.' + index + '.tipo_spesa',
                              Number(e.target.value)
                            )
                            if (e.target.value === '0') {
                              setFieldValue(
                                'movimenti.' + index + '.budget',
                                undefined
                              )
                            }
                          }}
                          value={values.movimenti[index].tipo_spesa}
                          isRequired
                          label={t('expense_type') ?? ''}
                        >
                          <option value={0}>{t('ineligible')}</option>
                          <option value={1}>{t('eligible')}</option>
                        </SelectChakraField>
                      </GridItem>
                      <GridItem>
                        <AutoCompleteField
                          name={'movimenti.' + index + '.budget'}
                          value={values.movimenti[index].budget}
                          params={{
                            progetto: id,
                            data_budget:
                              values.movimenti[index].data_competenza,
                          }}
                          url="/api/budgets/options/"
                          keyQuery="budgets-options"
                          isDisabled={
                            !values.movimenti[index].causale_oggetto ||
                            (values.movimenti[index].causale_oggetto &&
                              (values.movimenti[
                                index
                              ].causale_oggetto.disabled.includes('budget') ||
                                values.movimenti[index].tipo_spesa === 0))
                          }
                          label={t('budget') ?? ''}
                          isRequired={
                            values.movimenti[index].tipo_spesa === 1 &&
                            values.movimenti[index].causale_oggetto &&
                            !values.movimenti[index].bozza
                          }
                        />
                      </GridItem>
                      <GridItem colSpan={1}>
                        <SelectChakraField
                          name={'movimenti.' + index + '.periodo'}
                          label={t('report') ?? ''}
                          isRequired
                        >
                          <option value=""></option>
                          {[...Array(numeroAnnualita)].map((_, index) => (
                            <option key={index} value={index + 1}>
                              {index + 1}
                            </option>
                          ))}
                        </SelectChakraField>
                      </GridItem>
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes(
                          'tipo_procedura_acquisto'
                        ) &&
                        !values.movimenti[index].causale_oggetto.hide.includes(
                          'tipo_procedura_acquisto'
                        ) && (
                          <GridItem colSpan={1}>
                            <ReactSelectField
                              name={
                                'movimenti.' +
                                index +
                                '.tipo_procedura_acquisto'
                              }
                              label={t('purchase_procedure') ?? ''}
                              options={TIPO_PROCEDURA_ACQUISTO_OPTIONS}
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes(
                                  'tipo_procedura_acquisto'
                                )
                              }
                              value={
                                TIPO_PROCEDURA_ACQUISTO_OPTIONS.find(
                                  (option) =>
                                    option.value ===
                                    values.movimenti[index]
                                      .tipo_procedura_acquisto
                                ) ?? ''
                              }
                            />
                          </GridItem>
                        )}
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes(
                          'modalita_pagamento'
                        ) &&
                        !values.movimenti[index].causale_oggetto.hide.includes(
                          'modalita_pagamento'
                        ) && (
                          <GridItem colSpan={1}>
                            <ReactSelectField
                              name={
                                'movimenti.' + index + '.modalita_pagamento'
                              }
                              label={t('payment_method') ?? ''}
                              value={
                                optionsModalitaPagamento.find(
                                  (option) =>
                                    option.value ===
                                    values.movimenti[index].modalita_pagamento
                                ) ?? ''
                              }
                              onChange={(option) => {
                                setFieldValue(
                                  'movimenti.' + index + '.modalita_pagamento',
                                  option?.value
                                )
                                setFieldValue(
                                  'movimenti.' + index + '.conto_bancario',
                                  undefined
                                )
                                setFieldValue(
                                  'movimenti.' + index + '.valuta',
                                  progetto?.valuta_locale_principale
                                )
                              }}
                              options={
                                values.movimenti[index].causale ===
                                  'anticipo-cassa' ||
                                values.movimenti[index].causale ===
                                  'reso-anticipo-cassa'
                                  ? optionsModalitaPagamento.filter(
                                      (option) =>
                                        option.label.indexOf('CASSA') > -1
                                    )
                                  : optionsModalitaPagamento
                              }
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes(
                                  'modalita_pagamento'
                                )
                              }
                            />
                          </GridItem>
                        )}
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes('conto_bancario') &&
                        !values.movimenti[index].causale_oggetto.hide.includes(
                          'conto_bancario'
                        ) && (
                          <GridItem colSpan={1}>
                            <AutoCompleteField
                              name={'movimenti.' + index + '.conto_bancario'}
                              value={values.movimenti[index].conto_bancario}
                              url={`/api/progetti/${id}/options-conti-bancari/`}
                              keyQuery="conti-bancari-options"
                              params={{
                                modalita_pagamento:
                                  values.movimenti[index].modalita_pagamento,
                              }}
                              isDisabled={
                                values.movimenti[index].modalita_pagamento ===
                                undefined
                              }
                              onChange={(value: any) => {
                                setFieldValue(
                                  'movimenti.' + index + '.conto_bancario',
                                  value?.value
                                )
                                setFieldValue(
                                  'movimenti.' + index + '.valuta',
                                  value?.valuta
                                )
                              }}
                              label={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.hide.includes(
                                  'conto_destinazione'
                                )
                                  ? t('account') ?? ''
                                  : t('withdrawal_account') ?? ''
                              }
                              isRequired={
                                values.movimenti[index].modalita_pagamento &&
                                values.movimenti[index].causale_oggetto
                              }
                            />
                          </GridItem>
                        )}
                      <GridItem>
                        <ReactSelectField
                          name={'movimenti.' + index + '.valuta'}
                          label={t('currency') ?? ''}
                          options={optionsValute ?? []}
                          isRequired
                          value={
                            optionsValute.find(
                              (v) => v.value === values.movimenti[index].valuta
                            ) ??
                            optionsValute.find(
                              (v) =>
                                v.value === progetto?.valuta_locale_principale
                            )
                          }
                        />
                      </GridItem>
                      <GridItem>
                        <AmountField index={index} />
                      </GridItem>
                      <GridItem>
                        <InputField
                          name={'movimenti.' + index + '.aliquota_iva'}
                          label={t('vat') + ' (%)' ?? ''}
                          isRequired
                          type="number"
                        />
                      </GridItem>
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes(
                          'progetto_destinazione'
                        ) &&
                        !values.movimenti[index].causale_oggetto.hide.includes(
                          'progetto_destinazione'
                        ) && (
                          <GridItem>
                            <AutoCompleteField
                              name={
                                'movimenti.' + index + '.progetto_destinazione'
                              }
                              value={
                                values.movimenti[index].progetto_destinazione
                              }
                              onChange={(value: any) => {
                                setFieldValue(
                                  'movimenti.' +
                                    index +
                                    '.progetto_destinazione',
                                  value?.value
                                )
                                setFieldValue(
                                  'movimenti.' + index + '.conto_destinazione',
                                  undefined
                                )
                                setFieldValue(
                                  'movimenti.' + index + '.valuta_destinazione',
                                  undefined
                                )
                              }}
                              url="/api/progetti/options/"
                              keyQuery="progetti-options"
                              label={t('destination_project') ?? ''}
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes(
                                  'progetto_destinazione'
                                )
                              }
                            />
                          </GridItem>
                        )}
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes(
                          'modalita_pagamento_destinazione'
                        ) &&
                        !values.movimenti[index].causale_oggetto.hide.includes(
                          'modalita_pagamento_destinazione'
                        ) && (
                          <GridItem>
                            <ReactSelectField
                              name={
                                'movimenti.' +
                                index +
                                '.modalita_pagamento_destinazione'
                              }
                              onChange={(option) => {
                                setFieldValue(
                                  'movimenti.' +
                                    index +
                                    '.modalita_pagamento_destinazione',
                                  option?.value
                                )
                                setFieldValue(
                                  'movimenti.' + index + '.conto_destinazione',
                                  undefined
                                )
                                setFieldValue(
                                  'movimenti.' + index + '.valuta_destinazione',
                                  undefined
                                )
                              }}
                              label={t('payment_method_destination') ?? ''}
                              value={
                                optionsModalitaPagamento.find(
                                  (option) =>
                                    option.value ===
                                    values.movimenti[index]
                                      .modalita_pagamento_destinazione
                                ) ?? ''
                              }
                              options={optionsModalitaPagamento}
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes(
                                  'modalita_pagamento_destinazione'
                                )
                              }
                              isDisabled={
                                !values.movimenti[index].causale_oggetto ||
                                values.movimenti[
                                  index
                                ].causale_oggetto.disabled.includes(
                                  'modalita_pagamento_destinazione'
                                ) ||
                                (!values.movimenti[index]
                                  .progetto_destinazione &&
                                  values.movimenti[index].causale !==
                                    'cambio-valuta' &&
                                  values.movimenti[index].causale !==
                                    'prelievo')
                              }
                            />
                          </GridItem>
                        )}
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes(
                          'conto_destinazione'
                        ) &&
                        !values.movimenti[index].causale_oggetto.hide.includes(
                          'conto_destinazione'
                        ) && (
                          <GridItem>
                            <AutoCompleteField
                              name={
                                'movimenti.' + index + '.conto_destinazione'
                              }
                              params={{
                                progetto: id,
                                modalita_pagamento:
                                  values.movimenti[index]
                                    .modalita_pagamento_destinazione,
                                valuta:
                                  values.movimenti[index].causale ===
                                    'prelievo' ||
                                  values.movimenti[index].causale ===
                                    'giroconto-chiusura' ||
                                  values.movimenti[index].causale ===
                                    'trasferimento-a-progetto'
                                    ? values.movimenti[index].valuta
                                    : undefined,
                                not_valuta:
                                  values.movimenti[index].causale ===
                                  'cambio-valuta'
                                    ? values.movimenti[index].valuta
                                    : undefined,
                                not_id:
                                  values.movimenti[index].causale ===
                                    'cambio-valuta' ||
                                  values.movimenti[index].causale === 'prelievo'
                                    ? values.movimenti[index].conto_bancario
                                    : undefined,
                              }}
                              value={values.movimenti[index].conto_destinazione}
                              url={
                                values.movimenti[index].causale ===
                                  'cambio-valuta' ||
                                values.movimenti[index].causale === 'prelievo'
                                  ? `/api/progetti/${id}/options-conti-bancari/`
                                  : `/api/progetti/${values.movimenti[index].progetto_destinazione}/options-conti-bancari/`
                              }
                              keyQuery="conti-bancari-options"
                              onChange={(value: any) => {
                                setFieldValue(
                                  'movimenti.' + index + '.conto_destinazione',
                                  value?.value
                                )
                                setFieldValue(
                                  'movimenti.' + index + '.valuta_destinazione',
                                  value?.valuta
                                )
                              }}
                              label={t('destination_account') ?? ''}
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes(
                                  'conto_destinazione'
                                )
                              }
                              isDisabled={
                                !values.movimenti[index].causale_oggetto ||
                                values.movimenti[
                                  index
                                ].causale_oggetto.disabled.includes(
                                  'conto_destinazione'
                                ) ||
                                !values.movimenti[index]
                                  .modalita_pagamento_destinazione
                              }
                            />
                          </GridItem>
                        )}
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes(
                          'valuta_destinazione'
                        ) &&
                        !values.movimenti[index].causale_oggetto.hide.includes(
                          'valuta_destinazione'
                        ) && (
                          <GridItem>
                            <AutoCompleteField
                              name={
                                'movimenti.' + index + '.valuta_destinazione'
                              }
                              value={
                                values.movimenti[index].valuta_destinazione
                              }
                              url="/api/valute/options/"
                              keyQuery="valute-options"
                              label={t('currency_destination') ?? ''}
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes(
                                  'valuta_destinazione'
                                )
                              }
                            />
                          </GridItem>
                        )}
                      {values.movimenti &&
                        values.movimenti[index] &&
                        values.movimenti[index].causale_oggetto &&
                        !values.movimenti[
                          index
                        ].causale_oggetto.disabled.includes(
                          'importo_destinazione'
                        ) &&
                        !values.movimenti[index].causale_oggetto.hide.includes(
                          'importo_destinazione'
                        ) && (
                          <GridItem>
                            <InputField
                              name={
                                'movimenti.' + index + '.importo_destinazione'
                              }
                              label={t('amount_destination') ?? ''}
                              isRequired={
                                values.movimenti[index].causale_oggetto &&
                                values.movimenti[
                                  index
                                ].causale_oggetto.required.includes(
                                  'importo_destinazione'
                                )
                              }
                              type="number"
                            />
                          </GridItem>
                        )}
                      <GridItem>
                        <TextareaField
                          name={'movimenti.' + index + '.note'}
                          label={t('notes') ?? ''}
                          rows={2}
                          isRequired={false}
                        />
                      </GridItem>

                      <GridItem>
                        <InputFileField
                          name={'movimenti.' + index + '.allegato'}
                          label={t('attachment') ?? ''}
                          isRequired={false}
                        />
                      </GridItem>
                      <GridItem>
                        <InputFileField
                          name={'movimenti.' + index + '.allegato_2'}
                          label={t('attachment_2') ?? ''}
                          isRequired={false}
                        />
                      </GridItem>
                      <GridItem>
                        <InputFileField
                          name={'movimenti.' + index + '.allegato_3'}
                          label={t('attachment_3') ?? ''}
                          isRequired={false}
                        />
                      </GridItem>
                      <GridItem>
                        <InputFileField
                          name={'movimenti.' + index + '.allegato_4'}
                          label={t('attachment_4') ?? ''}
                          isRequired={false}
                        />
                      </GridItem>
                      <GridItem>
                        <InputFileField
                          name={'movimenti.' + index + '.allegato_5'}
                          label={t('attachment_5') ?? ''}
                          isRequired={false}
                        />
                      </GridItem>
                    </Grid>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
          </Grid>
          <ReactRouterPrompt when={isDirty && !isSubmitting}>
            {({ isActive, onConfirm, onCancel }) => (
              <Modal isOpen={isActive} onClose={onCancel} isCentered>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{t('unsaved_changes')}</ModalHeader>
                  <ModalBody>{t('unsaved_changes_message')}</ModalBody>
                  <ModalFooter>
                    <Button
                      size={'sm'}
                      color={'brand'}
                      _hover={{
                        bg: 'brand',
                        color: 'white',
                      }}
                      bg={'brandLight'}
                      mr={3}
                      onClick={onCancel}
                    >
                      {t('close')}
                    </Button>
                    <Button
                      size={'sm'}
                      bg={'brand'}
                      _hover={{
                        bg: 'brandSecondary',
                      }}
                      color={'white'}
                      onClick={onConfirm}
                    >
                      {t('confirm')}
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </ReactRouterPrompt>
        </Box>
      )}
    />
  )
}

export default function MovimentoMultipleForm({
  movimenti,
  save,
}: MovimentoFormProps) {
  const { t } = useTranslation()
  const { id } = useParams()

  const { user } = useAuthUser<AccountUser>()

  const MovimentoSchema = useMemo(() => {
    return Yup.object().shape({
      movimenti: Yup.array().of(
        Yup.object().shape({
          descrizione: Yup.string().required().label(t('description')),
          causale: Yup.string().required().label(t('causal')),
          periodo: Yup.string().required().label(t('report')),
          data_competenza: Yup.string().required().label(t('competence_date')),
          data_documento: Yup.string().required().label(t('document_date')),
          importo: Yup.number().required().label(t('amount')),
          aliquota_iva: Yup.number().required().label(t('vat')),
          valuta: Yup.string().required().label(t('currency')),
          numero_documento: Yup.string()
            .label(t('document_number'))
            .when(['causale_oggetto', 'bozza'], (values: any, schema: any) => {
              const causale_oggetto = values[0]
              const bozza = values[1]
              if (
                causale_oggetto?.required.includes('numero_documento') &&
                !bozza
              ) {
                return schema.required()
              } else {
                return schema
              }
            }),
          beneficiario: Yup.string()
            .label(t('beneficiary'))
            .when(['causale_oggetto', 'bozza'], (values: any, schema: any) => {
              const causale_oggetto = values[0]
              const bozza = values[1]
              if (
                causale_oggetto?.required.includes('beneficiario') &&
                !bozza
              ) {
                return schema.required()
              } else {
                return schema
              }
            }),

          ente: Yup.string()
            .label(t('entity'))
            .when(['causale_oggetto', 'bozza'], (values: any, schema: any) => {
              const causale_oggetto = values[0]
              const bozza = values[1]
              if (causale_oggetto?.required.includes('ente') && !bozza) {
                return schema.required()
              } else {
                return schema
              }
            }),
          fornitore: Yup.string()
            .label(t('supplier'))
            .when(['causale_oggetto', 'bozza'], (values: any, schema: any) => {
              const causale_oggetto = values[0]
              const bozza = values[1]
              if (causale_oggetto?.required.includes('fornitore') && !bozza) {
                return schema.required()
              } else {
                return schema
              }
            }),
          donatore: Yup.string()
            .label(t('donor'))
            .when(['causale_oggetto', 'bozza'], (values: any, schema: any) => {
              const causale_oggetto = values[0]
              const bozza = values[1]
              if (causale_oggetto?.required.includes('donatore') && !bozza) {
                return schema.required()
              } else {
                return schema
              }
            }),
          collaborazione: Yup.string()
            .label(t('collaboration'))
            .when(['causale_oggetto', 'bozza'], (values: any, schema: any) => {
              const causale_oggetto = values[0]
              const bozza = values[1]
              if (
                causale_oggetto?.required.includes('collaborazione') &&
                !bozza
              ) {
                return schema.required()
              } else {
                return schema
              }
            }),
          bene: Yup.string()
            .label(t('good'))
            .when(['causale_oggetto', 'bozza'], (values: any, schema: any) => {
              const causale_oggetto = values[0]
              const bozza = values[1]
              if (causale_oggetto?.required.includes('bene') && !bozza) {
                return schema.required()
              } else {
                return schema
              }
            }),
          modalita_pagamento: Yup.string()
            .label(t('payment_method'))
            .when(['causale_oggetto', 'bozza'], (values: any, schema: any) => {
              const causale_oggetto = values[0]
              const bozza = values[1]

              if (
                causale_oggetto?.required.includes('modalita_pagamento') &&
                !bozza
              ) {
                return schema.required()
              } else {
                return schema
              }
            }),
          progetto_destinazione: Yup.string()
            .label(t('destination_project'))
            .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
              if (
                causale_oggetto &&
                causale_oggetto.length > 0 &&
                causale_oggetto[0]?.required.includes('progetto_destinazione')
              ) {
                return schema.required()
              } else {
                return schema
              }
            }),
          modalita_pagamento_destinazione: Yup.string()
            .label(t('payment_method_destination'))
            .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
              if (causale_oggetto && causale_oggetto.length > 0) {
                if (
                  causale_oggetto[0]?.required.includes(
                    'modalita_pagamento_destinazione'
                  )
                ) {
                  return schema.required()
                } else {
                  return schema
                }
              } else {
                return schema
              }
            }),
          conto_destinazione: Yup.string()
            .label(t('destination_account'))
            .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
              if (causale_oggetto && causale_oggetto.length > 0) {
                if (
                  causale_oggetto[0]?.required.includes('conto_destinazione')
                ) {
                  return schema.required()
                } else {
                  return schema
                }
              } else {
                return schema
              }
            }),
          valuta_destinazione: Yup.string()
            .label(t('currency_destination'))
            .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
              if (causale_oggetto && causale_oggetto.length > 0) {
                if (
                  causale_oggetto[0]?.required.includes('valuta_destinazione')
                ) {
                  return schema.required()
                } else {
                  return schema
                }
              } else {
                return schema
              }
            }),
          importo_destinazione: Yup.number()
            .label(t('amount_destination'))
            .when('causale_oggetto', (causale_oggetto: any, schema: any) => {
              if (causale_oggetto && causale_oggetto.length > 0) {
                if (
                  causale_oggetto[0]?.required.includes('importo_destinazione')
                ) {
                  return schema.required()
                } else {
                  return schema
                }
              } else {
                return schema
              }
            }),
          budget: Yup.string()
            .label(t('budget'))
            .when('tipo_spesa', {
              is: (val: number) => {
                return val === 1
              },
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          conto_bancario: Yup.string()
            .label(t('account'))
            .when('modalita_pagamento', {
              is: (val: string) =>
                val !== undefined && val !== null && val !== '',
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
        })
      ),
    })
  }, [t])

  const { data: progetto } = useProgettoSimple(Number(id!))

  const initialVat = useMemo(() => {
    if (progetto?.paesi_intervento_data && progetto?.paesi_intervento_data[0]) {
      return progetto?.paesi_intervento_data[0]?.vat
    }
    return 0
  }, [progetto?.paesi_intervento_data])

  const initialValues = useMemo(() => {
    if (movimenti && movimenti.length > 0) {
      return {
        movimenti: movimenti.map((mov) => ({
          ...fromNullToUndefineFromObjectWithTypes(mov),
        })),
      }
    }
    return {
      movimenti: [
        {
          aliquota_iva: initialVat,
          progressivo: undefined,
          importo_iva: 0,
          causale: '',
          beneficiario: '',
          causale_oggetto: undefined,
          data_competenza: progetto?.data_blocco_compentenza
            ? dayjs(progetto?.data_blocco_compentenza)
                .add(1, 'day')
                .format('YYYY-MM-DD')
            : dayjs().format('YYYY-MM-DD'),
          data_documento: dayjs().format('YYYY-MM-DD'),
          tipo_spesa: undefined,
          riportata_e_c: false,
          riportata_e_c_destinazione: false,
          periodo: undefined,
          valuta: progetto?.valuta_locale_principale || undefined,
          valuta_destinazione: undefined,
          tipo_procedura_acquisto: undefined,
          bene: undefined,
          fornitore: undefined,
          ente: progetto?.ente_principale || undefined,
          donatore: undefined,
          collaborazione: undefined,
          budget: undefined,
          modalita_pagamento: undefined,
          modalita_pagamento_destinazione: undefined,
          importo_destinazione: undefined,
          conto_destinazione: undefined,
          conto_bancario: undefined,
          progetto_destinazione: undefined,
          movimento_anticipo: undefined,
          importo: undefined,
          descrizione: '',
          bozza: false,
          progetti_copia: undefined,
          movimenti_copia: undefined,
          progetto: Number(id!),
          numero_documento: '',
          note: '',
          duty_station: user?.permessi?.duty_station || undefined,
        },
      ],
    }
  }, [
    id,
    movimenti,
    progetto?.ente_principale,
    initialVat,
    progetto?.valuta_locale_principale,
    user?.permessi?.duty_station,
    progetto?.data_blocco_compentenza,
  ])

  const itemsBreadCrumbCreate = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('movements'),
        link: `/projects/${id}/movements`,
      },
      {
        label: t('create_multiple'),
      },
    ]
  }, [t, progetto, id])

  const itemsBreadCrumbEdit = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('movements'),
        link: `/projects/${id}/movements`,
      },
      {
        label: t('edit_multiple'),
      },
    ]
  }, [t, progetto?.codice, id])

  const [modeForm, setModeForm] = useState<'horizontal' | 'vertical'>(
    'horizontal'
  )

  return (
    <Formik
      validationSchema={MovimentoSchema}
      enableReinitialize
      onSubmit={(movimento, { setErrors, setSubmitting }) => {
        save(movimento).catch((err) => {
          setErrors(transformErrorForForm(err))
          setSubmitting(false)
        })
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
        dirty,
        setFieldValue,
        values,
      }) => (
        <form className="form-without-scrollbar" onSubmit={handleSubmit}>
          <Flex direction={'column'}>
            <HeaderActionsPage
              sticky
              breadCrumbs={
                <BreadCrumb
                  items={
                    movimenti ? itemsBreadCrumbEdit : itemsBreadCrumbCreate
                  }
                />
              }
              blockRight={
                <Box display={'flex'} alignItems={'center'}>
                  <Button
                    size={'sm'}
                    me={2}
                    onClick={() => {
                      if (modeForm === 'horizontal') {
                        setModeForm('vertical')
                      } else {
                        setModeForm('horizontal')
                      }
                    }}
                    border={'1px solid'}
                    borderColor={'brandBlue'}
                    color={'brandBlue'}
                    bg={'card'}
                    _hover={{
                      bg: 'brandBlue',
                      color: 'white',
                    }}
                  >
                    {modeForm === 'horizontal'
                      ? t('modality_vertical')
                      : t('modality_horizontal')}
                  </Button>
                  <Button
                    size={'sm'}
                    color={'white'}
                    isDisabled={isSubmitting}
                    type={'submit'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                  >
                    {isSubmitting && <Spinner size="sm" me={2} />}
                    {t('save')}
                  </Button>
                </Box>
              }
            />
            <MovimentoGridForm
              progetto={progetto}
              movimenti={movimenti}
              modeForm={modeForm}
            />
          </Flex>
        </form>
      )}
    </Formik>
  )
}
